<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-13 14:08:11
 * @LastEditTime: 2023-09-26 14:39:25
-->
<template>
  <z-header type="transparent"></z-header>
  <div v-if="!$isMobile">
    <z-banner :recruitingType="2"></z-banner>
    <div class="back_top">
      <div class="RT_cards">
        <div class="card" @click="toPosition(1)">
          <div class="campus">应届生招聘</div>
          <div class="apply">马上申请</div>
          <div class="C_bottom">
            <span>毕业时间：2024.10-2025.09</span>
          </div>
          <div class="icon"></div>
        </div>
        <div class="card" style="margin-left: 27px" @click="toPosition(2)">
          <div class="campus">实习生招聘</div>
          <div class="apply">马上申请</div>
          <div class="icon"></div>
        </div>
      </div>
      <z-title cn="招聘动态" en="ZHONG TONG ECOLOGY" style="width: 1200px; margin: 100px auto 0"></z-title>
      <div class="ecology" @mouseover="touchTab = true" @mouseout="touchTab = false">
        <el-row>
          <el-col :span="6" v-for="(item, index) in tagList" :key="index">
            <div
              :class="['tab_name', activeTab == item.value ? 'active_tab' : '']"
              @mouseover="activeTab = item.value"
              @click="pushToLocation(item.tabName)"
            >
              {{ item.tabName }}
            </div>
          </el-col>
        </el-row>
        <div v-if="activeTab == 3" class="ecolog_content">
          <div class="ecolog_left">
            <div class="left_title">校招公告</div>
            <div class="EName">CAMPUS ANNOUNCEMENT</div>
          </div>
          <div class="ecolog_right">
            <div
              class="list_title"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="pushToDetail('notice' + index)"
            >
              <div>{{ item.name }}</div>
              <div>{{ item.announcementTime ? $dayjs(item.announcementTime).format('YYYY-MM-DD') : '' }}</div>
              <div></div>
            </div>
          </div>
        </div>
        <div v-if="activeTab == 2" class="z-process">
          <process></process>
        </div>
        <div v-if="activeTab == 1" class="ecolog_content">
          <div class="ecolog_left">
            <div class="left_title">宣讲会</div>
            <div class="EName">LECTURE</div>
          </div>
          <div class="ecolog_right" style="padding: 12px 0">
            <div
              class="meeting_list"
              v-for="(item, index) in meetingList"
              :key="index"
              @click="pushToDetail('meeting' + index)"
            >
              <div class="meeting">
                <div class="meeting_title">{{ item.lectureName }}</div>
                <div class="meeting_time">
                  <span v-if="item.startTime">{{ $dayjs(item.startTime).format('YYYY-MM-DD') }}</span>
                  <span v-if="item.startTime" style="margin-left: 15px">
                    {{ $dayjs(item.startTime).format('HH:mm') }}
                  </span>
                  <span v-if="item.endTime">~{{ $dayjs(item.endTime).format('HH:mm') }}</span>
                </div>
                <div class="meeting_btn" @click.stop="pushToLecture(item)">预约报名</div>
                <div class="circle_blue"></div>
              </div>
            </div>
            <div v-if="!meetingList.length" style="padding: 28px 20px 28px 0">
              <div class="answer_title">
                <div style="font-size: 19px">宣讲会行程解锁中，期待线下的相遇</div>
                <div class="circle_blue"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab == 4" class="ecolog_content">
          <div class="ecolog_left">
            <div class="left_title">答疑专区</div>
            <div class="EName">Q &amp; A area</div>
          </div>
          <div class="ecolog_right">
            <div
              class="answer_title"
              v-for="(item, index) in answerList"
              :key="index"
              @click="pushToDetail('answer' + index)"
            >
              <div style="font-size: 19px; margin-left: 8px">{{ item.name }}</div>
              <div class="circle_blue"></div>
              <div class="arrow">
                <el-icon><Right /></el-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="ecology_more" style="margin-top: 40px" @click="pushDetail">
          了解更多
          <el-icon :size="20"><Right /></el-icon>
        </div>
      </div>
    </div>

    <div class="content">
      <z-title cn="中通生活" en="ZHONG TONG LIFE" style="margin-top: 60px"></z-title>
      <life :recruitingType="2" style="margin-top: 40px"></life>
      <z-title cn="员工故事" en="EMPLOYEE STORY" style="margin-top: 170px"></z-title>
      <story :recruitingType="2" style="padding: 40px 0 100px"></story>
    </div>
    <z-footer></z-footer>
  </div>
  <div v-else>
    <h5campus :noticeList="noticeList" :answerList="answerList" :meetingList="meetingList"></h5campus>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch, onMounted, getCurrentInstance, computed, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import ZBanner from '@/components/banner/banner-fullscreen.vue'
import life from '@/components/life/index.vue'
import { Right } from '@element-plus/icons'
import story from '@/components/story/story.vue'
import process from './components/process.vue'
import ZSearch from '@/components/layout/z-search.vue'
import store from '@/store'
import h5campus from '@/views/campus-management/h5-campus.vue'
import ZTitle from '@/components/layout/z-title.vue'
export default defineComponent({
  components: { life, Right, story, process, ZBanner, h5campus, ZTitle },
  setup() {
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const state = reactive({
      tagList: [
        { value: 1, tabName: '宣讲会' },
        { value: 2, tabName: '招聘流程' },
        { value: 3, tabName: '校招公告' },
        { value: 4, tabName: '答疑专区' }
      ],
      touchTab: null,
      time: null,
      meetingList: [],
      answerList: [],
      noticeList: [],
      activeTab: 1
    })
    onMounted(() => {
      state.touchTab = false
      getEcology()
    })
    watch(
      () => state.touchTab,
      (newVal) => {
        if (newVal) {
          clearInterval(state.time)
        } else {
          state.time = setInterval(() => {
            if (state.activeTab >= 4) {
              state.activeTab = 1
            } else {
              state.activeTab = Number(state.activeTab) + 1
            }
          }, 300000)
        }
        //  else {
        //   time = setInterval(() => {
        //     if (state.activeTab >= 4) {
        //       state.activeTab += 1
        //     } else {
        //       state.activeTab = 1
        //     }
        //   }, 1000)
        // }
        // else {
        //   time = setInterval(() => {
        //     if (state.activeTab >= 4) {
        //       state.activeTab += 1
        //     } else {
        //       state.activeTab = 1
        //     }
        //   }, 1000)
        // }
      },
      { deep: true }
    )
    function pushDetail() {
      router.push({ name: 'ecologyDetail' })
    }

    function toPosition(object) {
      router.push({ name: 'CampusPosition', query: { object } })
    }
    function pushToLecture(item) {
      router.push({ name: 'lecture', query: { id: item.id, name: item.lectureName } })
    }
    function getEcology() {
      proxy.$http.campus.getRecruitmentNews().then((res) => {
        state.noticeList = res.schoolAnnouncements ? res.schoolAnnouncements.splice(0, 4) : []
        state.meetingList = res.lectureInfos ? res.lectureInfos.splice(0, 4) : []
        state.answerList = res.qaInfos ? res.qaInfos.splice(0, 4) : []
      })
    }
    function pushToLocation(key) {
      router.push({ name: 'ecologyDetail', query: { activeName: key } })
    }
    function pushToDetail(key) {
      console.log(key)
      router.push({ name: 'ecologyDetail', query: { detailId: key } })
    }
    onBeforeUnmount(() => {
      clearInterval(state.time)
    })
    return {
      ...toRefs(state),
      pushDetail,
      toPosition,
      pushToLecture,
      getEcology,
      pushToLocation,
      pushToDetail
    }
  }
})
</script>

<style lang="scss" scoped>
// .top-img {
//   width: 100%;
//   position: relative;
//   height: 720px;
//   background: url('https://img2.baidu.com/it/u=1418213803,321416682&fm=253&fmt=auto&app=138&f=JPEG?w=1920&h=400');
//   // background: #66ccff;
//   background-size: cover;
//   background-position: 50%;
//   position: relative;
//   .search {
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: auto;
//     top: 363px;
//     &:deep(.social) {
//       width: 675px !important;
//     }
//   }
// }
.back_top {
  width: 100%;
  background: #fff;
  overflow: hidden;
  border-bottom-left-radius: 50% 25px;
  border-bottom-right-radius: 50% 20px;
}
.RT_cards {
  width: 1200px;
  height: 260px;
  margin: 64px auto 0;
  display: flex;
  justify-content: center;
  & > .card:nth-child(1) {
    background: url(~@/assets/campus/card_bg1.png);
    background-size: cover;
    .icon {
      width: 160px;
      height: 160px;
      position: absolute;
      right: 48px;
      top: 43px;
      background: url(~@/assets/campus/card_icon1.png) no-repeat;
      background-size: cover;
    }
  }
  & > .card:nth-child(2) {
    background: url(~@/assets/campus/card_bg2.png);
    background-size: cover;
    .icon {
      width: 160px;
      height: 160px;
      position: absolute;
      right: 48px;
      top: 48px;
      background: url(~@/assets/campus/card_icon2.png) no-repeat;
      background-size: cover;
    }
  }
  .card:hover {
    border: 1px solid #ffffff;
    box-shadow: 0px 15px 30px 0px rgba(132, 143, 154, 0.2);
  }
  .card {
    width: 539px;
    height: 240px;
    background: #ffffff;
    border-radius: 27px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    .campus {
      position: absolute;
      top: 63px;
      left: 75px;
      font-size: 40px;
      font-weight: 500;
      color: #333333;
    }
    .apply {
      position: absolute;
      top: 127px;
      left: 75px;
      width: 133px;
      height: 42px;
      background: linear-gradient(130deg, #66c5ff 0%, #3693ff 100%);
      border-radius: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
    }
    .C_bottom {
      height: 32px;
      position: absolute;
      bottom: 0;
      width: 589px;
      background: #ffffff;
      opacity: 0.7;
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      line-height: 32px;
      padding-left: 72px;
    }
  }
}

.content {
  width: 1200px;
  margin: auto;
}
.ecology {
  width: 1200px;
  margin: 50px auto 0;
  padding-bottom: 100px;
  .tab_name {
    text-align: center;
    font-weight: 500;
    color: #333333;
    font-size: 27px;
  }
  .active_tab {
    color: #57a4ff;
    font-weight: 600;
  }
  .ecolog_content {
    display: flex;
    margin-top: 32px;
    height: 240px;
    background: #f8fafb;
    border-radius: 27px;
    overflow: hidden;
    .ecolog_left {
      width: 240px;
      background: url(~@/assets/campus/ECOLOGY.png) no-repeat;
      background-size: cover;
      border-radius: 27px 0 0 27px;
      .left_title {
        margin-left: 31px;
        margin-top: 72px;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
      }
      .EName {
        width: 180px;
        margin-left: 31px;
        margin-top: 8px;
        word-wrap: break-word;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .ecolog_right {
      flex: 1;
      height: 240px;
      padding: 34px 0;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      flex-wrap: wrap;
      background: #f8fafb;
      .empty_content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 32px;
        margin-left: 30px;
        color: #333;
        font-weight: 600;
      }
      .list_title {
        width: 100%;
        height: 50px;
        box-sizing: border-box;
        padding-right: 20px;
        overflow: hidden;
        box-sizing: border-box;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: space-between;
        font-size: 19px;
        padding-left: 43px;
        position: relative;
        & > div:nth-child(1) {
          // width: 270px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 19px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 53px;
          line-height: 27px;
          margin-left: 8px;
          // display: inline-block;
        }
        & > div:nth-child(2) {
          display: inline-block;
          width: 100px;
          font-size: 16px;
          font-weight: 500;
          color: #666666;
        }
        & > div:nth-child(3) {
          width: 8px;
          height: 8px;
          background: #ffffff;
          border: 3px solid #3693ff;
          position: absolute;
          left: 21px;
          border-radius: 50%;
          margin-top: 5px;
        }
      }
      .meeting_list {
        width: 100%;
        .meeting {
          display: flex;
          padding: 0 37px 0 43px;
          margin: 15px 0 13px 0;
          position: relative;

          .meeting_title {
            width: 447px;
            font-size: 19px;
            font-weight: 500;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .meeting_time {
            font-size: 19px;
            font-weight: 500;
            color: #666666;
          }
          .meeting_btn {
            width: 107px;
            height: 37px;
            border-radius: 27px;
            border: 1px solid #57a4ff;
            font-size: 16px;
            font-weight: 500;
            color: #57a4ff;
            line-height: 37px;
            text-align: center;
            position: absolute;
            right: 37px;
            top: -8px;
            cursor: pointer;
          }
        }
        .meeting:hover {
          .meeting_title,
          .meeting_time {
            color: #3693ff;
          }
          .meeting_btn {
            background: linear-gradient(130deg, #66c5ff 0%, #3693ff 100%);
            color: #ffffff;
          }
        }
      }
      .answer_title {
        // min-width: 40%;
        width: 100%;
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding-left: 43px;
        // padding-right: 61px;
        position: relative;
        line-height: 27px;
        font-weight: 500;
        color: #333333;
        .arrow {
          position: absolute;
          width: 27px;
          height: 10px;
          top: 0px;
          right: 0;
        }
      }
    }
  }
  .ecology_more {
    text-align: center;
    font-weight: 500;
    color: #333333;
    font-size: 16px;
    cursor: pointer;
    i {
      position: relative;
      top: 5px;
    }
  }
}
.circle_blue {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 3px solid #3693ff;
  // box-sizing: border-box;
  border-radius: 50%;
  left: 21px;
  top: 7px;
}

.z-process {
  height: 530px;
  background: #f8fafb;
  margin-top: 32px;
  border-radius: 27px;
  overflow: hidden;
  :deep .c-item__circle {
    background-color: #f8fafb !important;
  }
}
</style>
