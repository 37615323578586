<template>
  <div class="banner">
    <h5-banner activeType="2"></h5-banner>
  </div>
  <div class="card card_campus" style="margin-top: -0.33rem" @click="pushTo(1)">
    <div>应届生招聘</div>
    <div>申请</div>
  </div>
  <div class="card card_trainee" style="margin-top: 0.25rem; margin-bottom: 0.75rem" @click="pushTo(2)">
    <div>实习生招聘</div>
    <div>申请</div>
  </div>
  <h5Title titleName="招聘动态"></h5Title>
  <van-tabs v-model:active="H5ActiveTab" class="h5Tab" style="margin-top: 0.2rem">
    <div class="Cline"></div>
    <van-tab title="宣讲会" name="0">
      <div class="notice" @click="pushToDetail">
        <div class="notice_title" v-for="(item, index) in meetingList" :key="index">
          <div class="h5circle"></div>
          <div>
            <div class="h5noticeTitle">{{ item.lectureName }}</div>
            <div class="meeting_time">
              <span v-if="item.startTime">{{ $dayjs(item.startTime).format('YYYY-MM-DD') }}</span>
              <span v-if="item.startTime" style="margin-left: 15px">{{ $dayjs(item.startTime).format('HH:mm') }}</span>
              <span v-if="item.endTime">~{{ $dayjs(item.endTime).format('HH:mm') }}</span>
            </div>
          </div>
          <div class="meeting_btn">
            <div class="btn" @click.stop="appointment(item)">预约报名</div>
          </div>
        </div>
        <div class="notice_title" v-if="!meetingList.length">
          <div class="h5circle"></div>
          <div style="width: 100%">
            <div class="h5noticeTitle" style="width: 100%">宣讲会行程解锁中，期待线下的相遇</div>
            <!-- <div class="meeting_time">
              <span v-if="item.startTime">{{ $dayjs(item.startTime).format('YYYY-MM-DD') }}</span>
              <span v-if="item.startTime" style="margin-left: 15px">{{ $dayjs(item.startTime).format('HH:mm') }}</span>
              <span v-if="item.endTime">~{{ $dayjs(item.endTime).format('HH:mm') }}</span>
            </div> -->
          </div>
        </div>
      </div>
    </van-tab>
    <van-tab title="招聘流程" name="1">
      <div class="process" @click="pushToDetail">
        <div class="process_head">网申/内推</div>
        <div style="display: flex; height: 100%">
          <div class="Trainee">
            <div class="C_steps">
              <div class="C_step" v-for="(item, index) in stepList" :key="index">
                <div class="C_step_left">
                  <div class="C_step_icon"></div>
                  <div class="C_step_line"></div>
                </div>
                <div class="C_step_right">
                  <div class="c-step__name">{{ item.name }}</div>
                  <div v-if="item.descr" class="c-step__descr">{{ item.descr }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-tab>
    <van-tab title="校招公告" name="2">
      <div class="notice" @click="pushToDetail">
        <div class="notice_title" v-for="(item, index) in noticeList" :key="index">
          <div class="h5circle"></div>
          <div class="h5noticeTitle">{{ item.name }}</div>
          <div class="h5Time">
            {{ item.announcementTime ? $dayjs(item.announcementTime).format('YYYY-MM-DD') : '' }}
          </div>
        </div>
      </div>
    </van-tab>
    <van-tab title="答疑专区" name="3">
      <div class="notice" @click="pushToDetail">
        <div class="notice_title" v-for="(item, index) in answerList" :key="index">
          <div class="h5circle"></div>
          <div class="h5noticeTitle" style="width: 6.15rem">{{ item.name }}</div>
        </div>
      </div>
    </van-tab>
    <div class="H5more">
      <span @click="pushToDetail">
        查看全部
        <el-icon><right /></el-icon>
      </span>
    </div>
  </van-tabs>
  <h5Title titleName="中通生活" style="margin-top: 0.67rem"></h5Title>
  <life :recruitingType="2"></life>
  <h5Title titleName="员工故事" style="margin-top: 0.67rem"></h5Title>
  <story :recruitingType="2"></story>
</template>
<script>
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, toRefs } from 'vue'
import h5Title from '@/components/title/h5title.vue'
import { Right } from '@element-plus/icons'
import story from '@/components/story/story.vue'
import life from '@/components/life/index.vue'
import { useRouter } from 'vue-router'
import H5Banner from '@/components/banner/banner-h5.vue'

export default defineComponent({
  components: {
    h5Title,
    Right,
    story,
    H5Banner,
    life
  },
  props: {
    noticeList: Array,
    answerList: Array,
    meetingList: Array
  },
  setup(props) {
    const router = useRouter()
    const { proxy } = getCurrentInstance()
    const state = reactive({
      H5ActiveTab: 0,
      stepList: [
        {
          name: '网申'
        },
        {
          name: '测评'
        },
        {
          name: '笔试',
          descr: '(仅面向IT类岗位)'
        },
        // {
        //   name: '1分钟演讲'
        // },
        {
          name: '无领导小组讨论',
          descr: '(仅面向管培生岗位)'
        },
        {
          name: '1-3轮1V1面试'
        },
        {
          name: 'Offer'
        }
      ]
    })
    const pushToDetail = () => {
      router.push({ name: 'ecologyDetail', query: { active: state.H5ActiveTab } })
    }
    const pushTo = (object) => {
      router.push({ name: 'CampusPosition', query: { object } })
    }
    function appointment(item) {
      router.push({ name: 'lecture', query: { id: item.id, name: item.lectureName } })
    }
    return {
      ...toRefs(state),
      props,
      pushToDetail,
      pushTo,
      appointment
    }
  }
})
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 3.5rem;
  // background: linear-gradient(131deg, #3550dc 0%, #27e9f7 100%);
}
.card {
  width: 7.15rem;
  height: 2.5rem;
  background: #ffffff;
  box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.42rem;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  & > div:nth-child(1) {
    position: absolute;
    top: 0.71rem;
    right: 1.63rem;
    font-size: 0.38rem;
    font-weight: 500;
    color: #333333;
  }
  & > div:nth-child(2) {
    position: absolute;
    width: 1.83rem;
    top: 1.3rem;
    right: 1.67rem;
    height: 0.58rem;
    line-height: 0.58rem;
    text-align: center;
    color: #fff;
    font-size: 0.29rem;
    font-weight: 500;
    background: linear-gradient(130deg, #66c5ff 0%, #3693ff 100%);
    border-radius: 0.42rem;
  }
}
.card_campus {
  background: url(~@/assets/h5/campus_btn.png) no-repeat;
  background-size: cover;
}
.card_trainee {
  background: url(~@/assets/h5/trainee_btn.png) no-repeat;
  background-size: cover;
}
.h5Tab {
  &:deep(.van-tabs__nav) {
    background: inherit !important;
  }
  &:deep(.van-tabs__line) {
    width: 1.38rem;
    height: 0.04rem;
    background: #57a4ff;
    border-radius: 0.33rem;
  }
  &:deep(.van-tab__text) {
    font-size: 0.29rem;
    color: #333333;
  }
  &:deep(.van-tab--active) {
    .van-tab__text {
      font-weight: 500;
      color: #10a5f9;
    }
  }
}
.notice {
  width: 7.15rem;
  background: #ffffff;
  box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  margin: 0.33rem auto;
  box-sizing: border-box;
  padding: 0.5rem 0.33rem;
  .notice_title {
    font-size: 0.29rem;
    font-weight: 500;
    color: #333333;
    padding-bottom: 0.35rem;
    border-bottom: 0.02rem solid #e9edf1;
    display: flex;
    position: relative;
    margin-top: 0.31rem;
    &:nth-child(1) {
      margin-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: none;
    }
    .h5circle {
      position: absolute;
      left: 0;
      top: 0.06rem;
      width: 0.17rem;
      height: 0.17rem;
      background: #ffffff;
      border: 0.04rem solid #3693ff;
      border-radius: 50%;
    }
    .h5noticeTitle {
      font-size: 0.29rem;
      font-weight: 500;
      color: #333333;
      margin-left: 0.33rem;
      width: 4.31rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .meeting_time {
      font-size: 0.25rem;
      font-weight: 500;
      color: #666666;
      margin-top: 0.13rem;
      margin-left: 0.33rem;
    }
    .meeting_btn {
      text-align: center;
      vertical-align: middle;
      .btn {
        width: 1.6rem;
        height: 0.5rem;
        border-radius: 0.42rem;
        border: 0.02rem solid #57a4ff;
        font-size: 0.29rem;
        font-weight: 500;
        color: #57a4ff;
        padding: 0.04rem 0.17rem;
        box-sizing: border-box;
        margin-top: 0.1rem;
        // line-height: 0.5rem;
      }
    }
    .notice_Time {
      margin-left: 0.31rem;
      font-size: 0.25rem;
      font-weight: 500;
      color: #666666;
    }
  }
}
.process {
  width: 7.15rem;
  background: #f6faff;
  box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  margin: 0.33rem auto 0;
  overflow: hidden;
  .process_head {
    width: 7.15rem;
    height: 0.83rem;
    background: linear-gradient(90deg, #66c5ff 0%, #3895ff 100%);
    font-size: 0.33rem;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 0.83rem;
  }
  .Trainee {
    width: 100%;
    background: rgba(229, 238, 247, 0.6);
  }
  .title {
    font-size: 0.29rem;
    font-weight: 600;
    color: #333333;
    margin-top: 0.33rem;
    margin-left: 0.58rem;
  }
}
.Cline {
  width: 7.15rem;
  height: 0.04rem;
  background: #e7ebf0;
  border-radius: 0.33rem;
  margin: -1px auto 0;
}
.C_steps {
  display: flex;
  flex-flow: column;
  margin-top: 0.69rem;
  margin-left: 0.58rem;
  margin-bottom: 0.46rem;
  .C_step {
    display: flex;
    .C_step_left {
      position: relative;
      padding: 0 0 0.5rem 0;
      // height: 0.87rem;
      .C_step_icon {
        width: 0.25rem;
        height: 0.25rem;
        border: 0.04rem solid rgba(16, 165, 249, 0.5);
        border-radius: 50%;
        box-sizing: border-box;
        z-index: 10;
        background: #fff;
        position: relative;
      }
      .C_step_line {
        position: absolute;
        width: 0.02rem;
        // min-width: 1px;
        height: 100%;
        left: 0.11rem;
        top: 0;
        z-index: 1;
        background: rgba(194, 204, 217, 1);
      }
    }
    .C_step_right {
      margin-top: -0.05rem;
      margin-left: 0.29rem;
      padding-bottom: 0.3rem;
      .c-step__name {
        font-weight: 500;
        color: #333333;
        font-size: 0.29rem;
      }
      .c-step__descr {
        color: #999;
        font-size: 0.2rem;
      }
    }
    &:last-child {
      .C_step_left {
        height: inherit;
        .C_step_line {
          width: 0;
          height: 0;
        }
      }
    }
  }
}
.IT {
  background: #f6faff;
}
.H5more {
  font-size: 0.29rem;
  font-weight: 500;
  color: #999999;
  text-align: center;
  margin-top: 0.1rem;
  overflow: hidden;
}
</style>
