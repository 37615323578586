<template>
  <div class="c-process">
    <div class="c-process__header">网申 / 内推</div>

    <div class="c-process__body">
      <div class="c-process__wrap">
        <div class="c-process__top"></div>
        <div class="c-process__bottom"></div>
        <div class="c-process__list">
          <ul>
            <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">网申</div>
              <div></div>
            </li>
            <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">测评</div>
              <div></div>
            </li>
            <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">笔试</div>
              <div class="c-item__desrc">（仅面向IT类岗位）</div>
            </li>
            <!-- <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">1分钟演讲</div>
              <div></div>
            </li> -->
            <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">无领导小组讨论</div>
              <div class="c-item__desrc">(仅面向管培生岗位)</div>
            </li>
            <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">1-3轮1V1面试</div>
              <div></div>
            </li>
            <li class="c-process__item">
              <div class="c-item__circle"></div>
              <div class="c-item__name">Offer</div>
              <div></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({})
</script>
<style lang="scss" scoped>
$height: 140px;
.c-process {
  overflow: hidden;
  border-radius: 27px;
  width: 100%;

  .c-process__header {
    background: #ff9900;
    height: 64px;
    font-size: 25px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 64px;
  }

  .c-process__body {
    width: 600px;
    margin: 0 auto;
    height: 450px;
    position: relative;

    img {
      width: 600px;
      position: absolute;
      top: 30px;
      left: 0;
    }
    .c-process__wrap {
      width: 460px;
      position: absolute;
      top: 64px;
      left: 45px;
    }
    .c-process__top {
      border-top: 3px solid #5a97fe;
      border-right: 3px solid #5a97fe;
      border-bottom: 3px solid #5a97fe;
      border-left: 0 solid #5a97fe;
      height: $height;
      border-radius: 0 $height $height 0;
      position: relative;
      right: -55px;
      top: 3px;
    }

    .c-process__bottom {
      border-top: 3px solid #5a97fe;
      border-left: 3px solid #5a97fe;
      border-bottom: 3px solid #5a97fe;
      border-right: 0 solid #5a97fe;
      height: $height;
      border-radius: $height 0 0 $height;
      position: relative;
      left: -10px;
    }

    .c-process__list {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: 400px;
      ul {
        li {
          &.c-process__item {
            text-align: center;
            position: absolute;
            &:nth-child(1) {
              top: 14px;
              left: 10px;
            }
            &:nth-child(2) {
              top: 14px;
              left: 180px;
            }
            &:nth-child(3) {
              top: 14px;
              left: 310px;
              width: 200px;
            }
            &:nth-child(4) {
              top: 156px;
              left: 150px;
              // width: 200px;
            }
            &:nth-child(5) {
              top: 301px;
              left: 20px;
            }
            &:nth-child(6) {
              top: 301px;
              left: 430px;
            }
            // &:nth-child(7) {
            //   top: 301px;
            //   left: 438px;
            // }
            .c-item__circle {
              width: 40px;
              height: 40px;
              background-color: #fff;
              margin: 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              &::after {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                background-color: #1f73fe;
                border-radius: 50%;
              }
            }

            .c-item__name {
              color: #606060;
              font-size: 26px;
              letter-spacing: 1px;
              font-weight: bold;
            }
            .c-item__desrc {
              color: #a6a6a6;
              font-size: 20px;
              letter-spacing: 1px;
              margin-top: 5px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
